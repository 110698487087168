/* Use these colors  */
/* https://gogo-react.coloredstrategies.com/app/dashboards/ecommerce */
@import url("./assets//satoshifonts/css/satoshi.css");

.container {
  display: contents;
  /* display: flex;
  flex-direction: row; */
}

.main-content {  
  margin-left: 250px; /* This should match the sidebar's width */  
  padding-left: 20px; /* Optional padding for better readability */  
  flex-grow: 1;  
}  

@media (max-width: 768px) {  
  .main-content {  
    margin-left: 0;  
    padding-left: 10px; /* Adjust padding for smaller screens */  
  }  
}  

/* Formatting for top menu container */  
.top-menu-container {  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  padding: 10px;
  background-color: #f8f9fa; /* Optional: Background color for top menu */  
}  
  
/* Formatting for top menu */  
.top-menu {  
  display: flex;  
  justify-content: flex-end;  
  align-items: center;  
  width: 100%;  
  padding-right: 10px; /* Add padding to create space from the right edge */  
}  
  
.top-menu-items {  
  display: flex;  
  align-items: center;  
}  


.badge-high {  
  background-color: #B8D7F1 !important;  
  color: #0D4D3D !important;  
  font-weight: normal !important;  
}  
  
.badge-medium {  
  background-color: #CEE292 !important;  
  color: #0D4D3D !important;  
  font-weight: normal !important;  
}  
  
.badge-low {  
  background-color: #D46728 !important;  
  color: #FFFDED !important;  
  font-weight: normal !important;  
}  

/* component cards */
.component-card {
  background-color: #ffffffe5 !important;
  border-radius: 8px;
  padding: 10px;
  margin: 20px;
  border: #eeeeee solid 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}
  

body {
  margin: 0;
  font-weight: 500;
  font-family: "Satoshi-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F0F0F0;
  color: #231f20;
  overflow-wrap: break-word;
}

h1 {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
}

h2 {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
}

h3 {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
}

h4 {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
}

h5 {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
}

h6 {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
}

.regular-bold {
  font-weight: 900;
}

.pricing-title {
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
  font-size: 1.5em;
  text-align: center !important;
}

.example-text {
  font-family: "Satoshi-Light";
  color: #0d4d3d;
  font-size: 0.9em;
}

.text-center-col {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card-dashboard {
  background-color: white;
  border-radius: 8px;
  border: #eeeeee solid 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* width: 300px; */
  padding: 16px;
  margin: 16px;
  position: relative;
}

.card-description {
  color: #818181;
}

.card-highlight-sky {
  color: #818181;
  border-left: #b8d7f1 solid 3px;
  padding-left: 10px;
}

.card-title .custom-form-label {
  font-weight: bold;
}

.form-tip {
  font-size: 0.8em;
}

.partner-alert {
  font-size: 0.8em;
  /* padding: 16px; */
  color: #0D4D3D;
  background-color: #CEE292;
  border-radius: 8px;
  border: #eeeeee solid 1px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.footeritems {
  text-align: center;
}

.footer-ack {
  font-size: 0.8em;
  width: 70%;
}

th {
  background-color: hsl(55, 100%, 97%) !important;
  color: #0d4d3d;
  font-family: "Satoshi-Black";
}

.field-badge-unselected {
  background-color: hsl(55, 100%, 98%) !important;
  border: 2px solid #b8d7f1;
  margin: 4px;
  padding: 8px;
  color: #0d4d3d;
  font-size: 0.9em;
  font-family: "Satoshi-Light";
  cursor: pointer;
}

.field-badge-selected {
  background-color: #b8d7f1 !important;
  border: 2px solid #0d4d3d;
  margin: 4px;
  color: #0d4d3d;
  font-size: 0.9em;
  font-family: "Satoshi-Light";
  /* cursor: pointer;   */
}

.delete-badge {
  background-color: #F0F0F0 !important;
  border: 2px solid #d46728;
  margin: 4px;
  color: #0d4d3d;
  font-size: 0.9em;
  font-family: "Satoshi-Light";
  cursor: pointer;
}

.download-badge {
  background-color: #F0F0F0 !important;
  border: 2px solid #0d4d3d;
  margin: 4px;
  color: #0d4d3d;
  font-size: 0.9em;
  font-family: "Satoshi-Light";
  cursor: pointer;
}

.unselected-badge {
  background-color: #F0F0F0 !important;
  border: 2px solid #b8d7f1;
  margin: 4px;
  color: #0d4d3d;
  font-size: 0.9em;
  font-family: "Satoshi-Light";
  cursor: pointer;
}

.selected-badge {
  background-color: #b8d7f1 !important;
  color: #3a3a3a;
  border: 2px solid #0d4d3d;
  margin: 4px;
  font-size: 0.9em;
  font-family: "Satoshi-Light";
  padding: 1rem;
  cursor: pointer;
}

.unselected-button {
  background-color: #cee292 !important;
  border-radius: 10px;
  color: #0d4d3d !important;
  padding: 5px;
  font-size: 1.1em;
  font-family: "Satoshi-Medium";
  margin-top: 15px;
}

.selected-button {
  background-color: #0d4d3d !important;
  border-radius: 10px;
  color: #F0F0F0;
  padding: 5px;
  font-size: 1.1em;
  font-family: "Satoshi-Bold";
  margin-top: 15px;
}

.generate-text-selected {
  color: #005504ad;
  font-size: 1.1em;
  padding: 6px;
  /* margin: 8px;
  border-radius: 4px;
  text-align: right; */
  /* Add more custom styles as needed */
}

.generate-text-unselected {
  font-size: 1.1em;
  padding: 6px;
  /* Add more custom styles as needed */
}

.cancel-badge {
  color: #00b11dad !important;
  font-size: 0.8em;
  font-weight: 700;
  cursor: pointer;
}

.delete-badge {
  color: #ff0000 !important;
  font-size: 0.8em;
  font-weight: 700;
  cursor: pointer;
}

.custom-textarea {
  height: 5em;
  /* Adjust as needed */
  word-wrap: break-word;
  font-size: 1rem;
  resize: "vertical";
  overflow: visible;
  border-radius: 10px;
  /* Rounded corners */
  padding: 8px;
  /* Add some padding for aesthetics */
  border: 2px solid #0d4d3d;
  width: 90%;
  overflow: "hidden";
  resize: "none";
}

.notification {
  margin-top: 10px;
  border-radius: 10px;
  /* Rounded corners */
  padding: 8px;
  /* Add some padding for aesthetics */
  background: #0d4d3d;
  font-family: "Satoshi-Bold";
  color: white;
  width: 25%;
}

.btn {
  background-color: #0d4d3d;
  color: white;
  /* padding: 1rem 1.5rem; */
  font-size: 1.5rem;
}

.button-disabled {
  background-color: #bfc2c1 !important;
  border: 2px solid #0d4d3d !important;
  color: #0d4d3d !important;
  /* padding: 1rem 1.5rem; */
}

.icon-large {
  font-size: 30px;
  /* Change to your desired size */
  margin-right: 10px;
  /* Add spacing between icon and text */
}

.icon-right {
  float: right;
  font-size: 1.5em;
  cursor: pointer;
}

.icon-right-spinning {
  float: right;
  font-size: 1.2em;
  cursor: pointer;
  animation: spin 1.5s linear infinite;
}

.icon-left {
  float: left;
  font-size: 1.5em;
  cursor: pointer;
}

.icon-left-spinning {
  float: left;
  font-size: 1.2em;
  cursor: pointer;
  animation: spin 1.5s linear infinite;
}

.icon-xl {
  font-size: 40px;
}

.chatInput {
  width: 90%;
}

.adminBackground {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 60px;
}

.adminContainer {
  background-color: #ffffffe5 !important;
  border-radius: 10px;
  padding: 10px;
  margin: 20px;
}

a {
  color: #0d4d3d;
}


/* for side menu */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.sidebar img {
  max-height: 70px;
  max-width: 180px;
  padding: 10px;
}

.sidebar.open {
  transform: translateX(0);
}

.hamburger {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  cursor: pointer;
}

.menu {
  margin-top: 50px;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu ul li {
  padding: 15px;
  text-align: left;
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(0);
  }

  .hamburger {
    display: none;
  }
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #6c757d;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.2s;
  width: calc(100% - 20px);
  /* Adjust for padding */
}

.sidebar-item.active {
  background-color: #9ccec1;
  color: #007bff;
  border-radius: 8px;
  width: calc(100% - 20px);
  /* Adjust for padding */
}

.sidebar-item:hover {
  background-color: #e9ecef;
  border-radius: 8px;
  width: calc(100% - 20px);
  /* Adjust for padding */
}

.sidebar-item .icon {
  margin-right: 10px;
}

.sidebar-section {
  margin: 20px 0 10px 10px;
  color: #6c757d;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
}

.icon {
  width: 20px;
  height: 20px;
}

.sidebarColumn {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  height: 100%;
}

.sidebar a {
  color: #0d4d3d;
  text-decoration: none;
  font-family: "Satoshi-Regular";
  margin: 1px;
  padding: 8px 10px;
  /* Adjust padding to fit width */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: calc(100% - 20px);
  /* Adjust for padding */
}

.sidebar a.active {
  text-align: center;
  color: #0D4D3D;
  background-color: #9ccec1;
  border-radius: 8px;
  padding: 10px;
  font-family: "Satoshi-Bold";
  align-items: center;
  cursor: pointer;
  width: calc(100% - 20px);
  /* Adjust for padding */
}


/* for Mobile menu */

/* Hide the image by default */  
.mobile-logo {  
  display: none;  
}  
  
/* Show the image on mobile view */  
@media (max-width: 768px) {  
  .mobile-logo {  
    display: block;  
    max-height: 70px;
    max-width: 180px;
    padding: 10px;
  }  
}  

.mob-menu-icon {
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  font-size: 1.3em;
  font-family: "Satoshi-Regular" !important;
  margin: 10px;
}

.mob-menu-item {
  display: flex;  
  align-items: center;  
  font-size: 1.3em;
  font-family: "Satoshi-Regular" !important;
  text-align: center;
  background-color: #f8f9fa;
}

.mob-menu-item a {
  text-decoration: none;
  color: black;
  padding-left: 10px;
  /* text-transform: uppercase; */
}

/* for builderprogress */
.mobilemenutext {
  font-size: 1.5em;
  color: #0d4d3d;
}

.mobilemenutextselected {
  font-size: 1.5em;
  color: #0d4d3d;
  font-weight: 700;
}

.bordercolumn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 3em;
  /* border: 1px solid #0D4D3D; */
  margin: 0px;
  height: 100px;
}

.line {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 3px;
  padding: 0px;
  margin: 0px;
  background-color: #FFFDED;
  transform: translateY(-50%);
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: #FFFDED;
  border-radius: 50%;
}

.current-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-color: #B8D7F1;
  border-radius: 50%;
  border: 2px solid #FFFDED;
}

.circle-text {
  position: absolute;
  top: calc(50% - 45px);
  /* Adjust the vertical position as needed */
  left: 50%;
  font-family: "Satoshi-Black";
  transform: translateX(-50%);
  font-size: 15px;
  /* Set your desired font size */
  color: #0d4d3d;
  white-space: nowrap;
  /* Prevent text from wrapping */
  text-transform: uppercase;
}

/* for define columns */
.preview-data-header {
  font-size: 1em;
  font-weight: 700;
  padding-bottom: 0.5em;
  text-decoration-line: underline;
  text-decoration-color: #0d4d3d;
  text-decoration-thickness: 3px;
}

.preview-data {
  font-size: 0.9em;
  font-family: "Courier", monospace;
  border: #3a3a3a 0.2px solid;
  margin-left: -1px;
  vertical-align: text-top;
  white-space: nowrap;
}

.preview-data-delete {
  font-size: 1em;
  font-family: "Courier", monospace;
  font-weight: 700;
  color: #ff0000;
  border: #ff0000 0.2px solid;
  margin-left: -1px;
  vertical-align: text-top;
  white-space: nowrap;
}

.preview-generated-content {
  white-space: pre-line;
}

.hyperlink {
  text-decoration-line: underline;
  color: #0d4d3d;
  text-decoration-thickness: 2px;
}

.vh-100 {
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.smallerlogo {
  height: 100%;
  margin-bottom: 20px;
  object-fit: cover;
  width: 70%;
}

.full-height-img {
  height: 100%;
  object-fit: cover;
  width: auto;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's on top of other elements */
}

.spinner-icon {
  font-size: 3rem;
  /* Adjust size as needed */
  animation: spin 2s linear infinite;
  color: #d46728;
}

.tick-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Centers elements horizontally */
  justify-content: center;
  /* Centers elements vertically if needed */
  text-align: center;
  /* Ensures text inside elements is centered */
}

.tick-icon {
  font-size: 3rem;
  /* Adjust size as needed */
  color: #0d4d3d;
  text-align: center !important;
}

.tick-text {
  font-size: 2rem;
  /* Adjust size as needed */
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
  text-align: center !important;
}

.tick-text-sml {
  font-size: 1rem;
  /* Adjust size as needed */
  font-family: "Satoshi-Bold";
  color: #0d4d3d;
  text-align: center !important;
}

/* pricing component */
.toggle-switch {  
  display: inline-flex;  
  border: 1px solid #0d4d3d;  
  border-radius: 25px;  
  margin-bottom: 20px;  
}  
  
.toggle-switch button {  
  padding: 10px 20px;  
  border: none;  
  background: none;  
  cursor: pointer;  
  outline: none;  
  color: #0d4d3d;  
  font-weight: bold;  
}  
  
.toggle-switch .active {  
  background-color: #0d4d3d;  
  color: white;  
  border-radius: 25px;  
}  
  
.pricing-table {  
  display: flex;  
  justify-content: center;  
  gap: 20px;  
}  
  
.pricing-plan h2 {  
  margin-top: 0;  
}  
  
.price {  
  font-size: 24px;  
  font-weight: bold;  
}  
  
.pricing-plan ul {  
  list-style: none;  
  padding: 0;  
  text-align: left;  
}  
  
.pricing-plan ul li {  
  margin: 10px 0;  
}  
  
.pricing-plan button {  
  background-color: #0d4d3d;  
  color: white;  
  border: none;  
  border-radius: 25px;  
  padding: 10px 20px;  
  cursor: pointer;  
  font-size: 16px;  
  outline: none;  
}  
  
.pricing-plan button:hover {  
  background-color: #0d4d3d;  
}  

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.divider {  
  padding: 10px;
  border-top: 2px solid #0D4D3D;  
  width: 100%;  
  margin: 10px 0; /* Adjust spacing as needed */  
}  

/* create account page */
.createaAccountBackground {
  background-image: url("assets/images/jummbobillboard.png");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.createaAccountform-container {
  background-color: #F0F0F0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
}

.menu-bottom-line {
  border-bottom: 2px solid gray;
  /* Adjust the color and thickness as needed */
  box-shadow: 0 4px 2px -2px gray;
  /* Adjust the shadow as needed */
  padding-bottom: 3px;
  margin-bottom: 5px;
}

/* Media query for mobile devices */
@media (max-width: 768px) {

  .notification {
    width: 100%;
  }

  .footer-ack {
    width: 90%;
    /* Stretch to 90% width on mobile devices */
  }

  .col {
    padding: 5 !important;
  }

  .row {
    padding: 0 !important;
  }

  .adminBackground {
    padding: 10px !important;
  }
}